import idleCallback, { timings } from "common/utils/ui/idle_callback";
import UAParser from "ua-parser-js";
import { track } from "jiffy-analytics";
import { waitForWindowVariable } from "common/utils/ui/wait_for";
import { readCookie, writePersistentCookie } from "common/utils/cookie";

const initZendesk = tags => {
  if (tags && tags.length) {
    window.zE("messenger:set", "conversationTags", tags);
  }

  window.zE("messenger:on", "close", () => {
    writePersistentCookie("zeKeepWebWidgetClose", 1);
  });

  window.zE("messenger:on", "open", () => {
    track("zendesk_chat_user_event", { action: "Chat Opened" });
  });

  const zeKeepWebWidgetClose = readCookie("zeKeepWebWidgetClose");

  if (window.zendeskLaunchChat !== "true" || zeKeepWebWidgetClose) return;

  const device = new UAParser().getDevice();
  if (["mobile", "tablet"].includes(device.type)) return;

  window.zE(() => {
    window.zE("messenger", "open");
  });
};

const initZenDeskChat = key => {
  const script = document.createElement("script");
  script.async = true;
  script.id = "ze-snippet";

  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  document.body.appendChild(script);
};

const initWidget = () => {
  idleCallback(() => {
    initZenDeskChat(window.zenDeskChatConfig.key);
  }, timings.zenDesk);
  waitForWindowVariable(
    "zE",
    () => {
      initZendesk(JSON.parse(window.zenDeskChatConfig.tags));
    },
    () => {
      console.error("ZenDesk is not loaded");
    }
  );
};

if (
  document.readyState === "complete" ||
  document.readyState === "loaded" ||
  document.readyState === "interactive"
) {
  initWidget();
} else {
  document.addEventListener("DOMContentLoaded", initWidget);
}
